
import axios from 'axios';
import * as commonMethods from "./CommonMethods";
import { simpleDialog } from './SimpleDialog';

const SetupInterceptors = (navigate) => {
  
  axios.interceptors.request.use((request) => {
    if (!commonMethods.isNullOrUndefined(localStorage.getItem("Authorization"))) {
      request.headers['Authorization'] = `Bearer ${(localStorage.getItem("Authorization"))}`;
    }
    const d = new Date();
    let offset = d.getTimezoneOffset();
    request.headers['offset'] = offset;
    return request;
  })

  axios.interceptors.response.use(async (response) => {
    return response;
  }, async (error) => {

    
    if(!commonMethods.isNullOrUndefined(error)){
      debugger;
      if (error.response && ((error.response.data && error.response.data.status === 401) || error.response.status === 401)) {
        localStorage.clear();
        navigate('login');
        
        // let status_details = {...commonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.message }
        // status_dialog(status_details, () => {
          return Promise.reject(error);
        // });
      } else if (error.response && error.response.data && error.response.status === 403) {
        return error.response;
      }
      else if(error.response && ((error.response.data && error.response.data.status === 500) || error.response.status === 500)){
        debugger;
        simpleDialog(`${error?.response?.data?.message}`,()=>{
        });
      }
      else if(error.response && ((error.response.data && error.response.data.status === 400) || error.response.status === 400)){
        // let status_details = {...commonMethods.STATUS_POPUP_DETAILS.error, message: error?.response?.data?.data[0] }
        
      }
      return Promise.reject(error) ;
    }
  })
}

export default SetupInterceptors









