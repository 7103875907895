import './App.scss';
import Routing from './Routing';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import SetupInterceptors from "./utils/API-interceptor";
import { SimpleDialog } from './utils/SimpleDialog';
import { createContext, useState } from 'react';

import * as commonMethods from './utils/CommonMethods';
import useDeviceProperties from './utils/useDeviceProperties';
import { ConfirmDialog } from './utils/ConfirmDialog';

export const SideMenuContext = createContext(null);



function AxiosInterceptorNavigate() {
  let navigate = useNavigate();
  SetupInterceptors(navigate);
  return <></>;
}


function App() {
  const deviceProperties = useDeviceProperties();
  const [sideMenu, setSideMenu] = useState({ "open": deviceProperties.isMobileDevice ? false : true, sideMenuItems: commonMethods.getMenuList() });

  return (
      <BrowserRouter>
          <SideMenuContext.Provider value={{ sideMenu, setSideMenu }}>
            <div className="d-flex custom-fill">
              <Routing />
              <SimpleDialog />
              <ConfirmDialog />
              <AxiosInterceptorNavigate />
            </div>
          </SideMenuContext.Provider>
      </BrowserRouter>
  );
}

export default App;
