


import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material";
import create from "zustand";

const useSimpleDialogStore = create((set) => ({
    message: "",
    onSubmit: undefined,
    close: () =>
      set({
        onSubmit: undefined,
      }),
  }));

const SimpleDialog = () => {
    const { message, onSubmit, close } = useSimpleDialogStore();
    
  return (
    <Dialog open={Boolean(onSubmit)} onClose={close}>
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" 
                    className='custom-button'
                    onClick={()=>{
                      onSubmit();
                      close();
                    }}
            >
            Okay
            </Button>
      </DialogActions>
    </Dialog>
  )
}

export {SimpleDialog}

export const simpleDialog = (message, onSubmit) => {
    useSimpleDialogStore.setState({ message, onSubmit });
  };
