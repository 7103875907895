


import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material";
import create from "zustand";

const useConfirmDialogStore = create((set) => ({
    message: "",
    onSubmit: undefined,
    close: () =>
      set({
        onSubmit: undefined,
      }),
  }));

const ConfirmDialog = () => {
    const { message, onSubmit, close } = useConfirmDialogStore();
    
  return (
    <Dialog open={Boolean(onSubmit)} onClose={close}>
        <DialogTitle>Notification</DialogTitle>
        <DialogContent>
            <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" 
                    className='custom-button'
                    onClick={()=>{
                      onSubmit(true);
                      close();
                    }}
            >
            Yes
            </Button>
            <Button variant="contained" 
            className='custom-background-red'
                    onClick={()=>{
                      onSubmit(false);
                      close();
                    }}
            >
            No
            </Button>
      </DialogActions>
    </Dialog>
  )
}

export {ConfirmDialog}

export const confirmDialog = (message, onSubmit) => {
    useConfirmDialogStore.setState({ message, onSubmit });
  };
