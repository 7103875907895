import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom'
import Loading from './components/Loading';
import IsLoginGuard from './components/guards/IsLoginGuard';
import AuthenticatedGuard from './components/guards/AuthenticatedGuard';


const Login = React.lazy(() => import('./pages/login/Login'));
const Home = React.lazy(() => import('./pages/home/Home'));
const Menu = React.lazy(() => import('./pages/menu/Menu'));
const Summary = React.lazy(() => import('./pages/summary/Summary'));
const ProductsTable = React.lazy(() => import('./pages/products/ProductsTable'));
const ProductForm = React.lazy(() => import('./pages/products/ProductForm'));
const CustomersTable = React.lazy(() => import('./pages/customers/CustomersTable'));
const CustomerForm = React.lazy(() => import('./pages/customers/CustomerForm'));
const UsersTable = React.lazy(() => import('./pages/users/UsersTable'));
const UserForm = React.lazy(() => import('./pages/users/UserForm'));
const DeliveryForm = React.lazy(() => import('./pages/deliveries/DeliveryForm'));
const CollectionForm = React.lazy(() => import('./pages/collection/CollectionForm'));
const DeliveriesTable = React.lazy(() => import('./pages/deliveries/DeliveriesTable'));
const CollectionTable = React.lazy(() => import('./pages/collection/CollectionTable'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));
const CustomerHistory = React.lazy(() => import('./pages/customers/CustomerHistory'));




function Routing() {
  return (
    <React.Suspense fallback={<Loading />}>
        <Routes>
            <Route exact path="" element={<Navigate to="/login" />}  ></Route>
            <Route element={ <IsLoginGuard /> }>
              <Route path="/login" element={<Login />} />
            </Route>
            <Route element={ <AuthenticatedGuard /> }>
              <Route path="/home" element={<Home />}>
                <Route exact path="" element={<Navigate to="/home/dashboard" />}  ></Route>
                <Route path='menu' element={<Menu />}></Route>
                <Route path='dashboard' element={<Dashboard />}></Route>
                <Route path='summary' element={<Summary />}></Route>
                <Route path='delivery' element={ <DeliveryForm /> }></Route>
                <Route path='delivery/:_id' element={ <DeliveryForm /> }></Route>
                <Route path='deliveries' element={ <DeliveriesTable /> }></Route>
                <Route path='collect' element={ <CollectionForm /> }></Route>
                <Route path='collect/:_id' element={ <CollectionForm /> }></Route>
                <Route path='collection' element={ <CollectionTable /> }></Route>
                <Route path='products' element={<ProductsTable />}></Route>
                <Route path='product' element={<ProductForm />}></Route>
                <Route path='product/:_id' element={<ProductForm />}></Route>
                <Route path='customers' element={<CustomersTable />}></Route>
                <Route path='customer' element={<CustomerForm />}></Route>
                <Route path='customer/:_id' element={<CustomerForm />}></Route>
                <Route path='customer-history/:_id' element={<CustomerHistory />}></Route>
                <Route path='users' element={<UsersTable />}></Route>
                <Route path='user' element={<UserForm />}></Route>
                <Route path='user/:_id' element={<UserForm />}></Route>
              </Route>
            </Route>            
        </Routes>
    </React.Suspense>
  )
}

export default Routing
