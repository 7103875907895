/* eslint-disable no-unused-vars */

import * as XLSX from "xlsx";
import { FiThumbsUp } from 'react-icons/fi';
import { MdOutlineErrorOutline } from 'react-icons/md';
import * as API from "./API";
import moment from "moment";

export const concateNames = (firstName, middleName, lastName) => {
    return (!isNullOrUndefined(firstName) ? (firstName.toString() + ' ') : '') + (!isNullOrUndefined(middleName) ? (middleName.toString() + ' ') : '') + (!isNullOrUndefined(lastName) ? (lastName.toString()) : '');
}

export const removeEmptyObjectFromArray = (data) => {
    return JSON.stringify(data) === '[{}]' ? [] : data;
}

export const isNullOrUndefined = (value) => {
    return (value === null || value === undefined || value === '' || (typeof (value) === 'object' ? Object.keys(value).length === 0 : false))
}

export const getProperArrayFormat = (value) => {
    let string_format = JSON.stringify(value)
    let return_format = string_format === '[{}]' ? [] : JSON.parse(string_format)
    return return_format
}


export const getMenuList = async ()=>{
    let menu=
    [
        {
            _id:"1",
            screen_name:"Dasboard",
            path:"/home/dashboard",
            icon:"dashboard"
        },
        {
            _id:"2",
            screen_name:"Deliveries",
            path:"/home/deliveries",
            icon:"delivery"
        },
        {
            _id:"3",
            screen_name:"Receivables",
            path:"/home/collection",
            icon:"receivable"
        },
        {
            _id:"4",
            screen_name:"Customers",
            path:"/home/customers",
            icon:"customer"
        },
        {
            _id:"5",
            screen_name:"Products",
            path:"/home/products",
            icon:"product"
        },
        {
            _id:"6",
            screen_name:"Users",
            path:"/home/users",
            icon:"user"
        }
    ]
    return menu;
}


export const CapitalizeFirstLetter = (mySentence) => {
    return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
}

export const exportJSONtoExcel = (data, name) => {

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, name);
}

export const copyToClipboard = (text) => {
    var textField = document.createElement('textarea')
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
}

export const updateFormFields = (obj1, obj2) => {
    for (let prop of Object.keys(obj2)) {
        if (prop in obj1) {
            if (typeof (obj2[prop]) === 'object' && Array.isArray(obj2[prop]) && !isNullOrUndefined(obj2[prop][0])) {
                if (!isNullOrUndefined(obj1[prop][0]) && Object.keys(obj1[prop][0]).length) {
                    let objectKeys = { ...obj1[prop][0] };
                    obj1[prop] = [];
                    for (let i = 0; i < obj2[prop].length; i++) {
                        let object = { ...objectKeys }
                        for (let nestedProp of Object.keys(objectKeys)) {
                            if (nestedProp in object) {
                                object[nestedProp] = !isNullOrUndefined(obj2[prop][i][nestedProp]) ? obj2[prop][i][nestedProp] : objectKeys[nestedProp];
                            }
                        }
                        obj1[prop].push(object);
                    }
                }
                else {
                    obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
                }
            }
            else if (typeof (obj2[prop]) === 'object' && !Array.isArray(obj2[prop]) && !isNullOrUndefined(obj2[prop])) {
                if (!isNullOrUndefined(obj1[prop]) && Object.keys(obj1[prop]).length) {
                    for (let nestedProp of Object.keys(obj2[prop])) {
                        if (nestedProp in obj1[prop]) {
                            obj1[prop][nestedProp] = !isNullOrUndefined(obj2[prop][nestedProp]) ? obj2[prop][nestedProp] : obj1[prop][nestedProp];
                        }
                    }
                }
                else {
                    obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
                }
            }
            else if (typeof (obj2[prop]) !== 'object') {
                obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
            }
        }
    }

    return obj1;
}

export const arrayToString = (arrayName, fieldName) => {
    if (!isNullOrUndefined(arrayName)) {
        let newArray = [];
        for (let i = 0; i < arrayName.length; i++) {
            newArray.push(arrayName[i][fieldName]);
        }
        let arrayData = newArray.join(", ");
        return arrayName.length !== 0 ? arrayData : 'NA';
    }
    else {
        return isNullOrUndefined(arrayName) ? 'NA' : arrayName.length !== 0 ? "" : 'NA';
    }
};

export function displayDate(date) {
    let d = new Date(date);
    let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return months[d.getMonth()] + ', ' + d.getDate() + ', ' + d.getFullYear()
}

export function defaultDateFormat(date){
    return moment(date).format('MMM DD, YYYY');
}

export function defaultDateTimeFormat(date){
    return moment(date).format('MMM DD, YYYY HH:MM:SS');
}

export function subtractMonths(date,months){
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() - months);
    return dateCopy;
}


export function sortByProperty(property) {
    return function (a, b) {
        if (isNullOrUndefined(property)) {
            if (a > b)
                return 1;
            else if (a < b)
                return -1;

            return 0;
        }
        else {
            if (a[property] > b[property])
                return 1;
            else if (a[property] < b[property])
                return -1;
            return 0;
        }
    }
}

export function customSort(data, key, sortType) {
    if (!isNullOrUndefined(data)) {
        let ascSort = data.sort(sortByProperty(key));
        return sortType === 'desc' ? ascSort.reverse() : ascSort;
    }
    else {
        return [];
    }
}

export function customShowornot(data, key, sortType, show_only_access) {

    if (show_only_access) {
        return data.filter(el => { return el[key] === true });
    }
    else {
        return customSort(data, key, sortType);
    }

}

export function removeHTMLTags(str) {
    let output;

    output = str.replace(/(<([^>]+)>)/ig, '')
        .replace(/&nbsp;/ig, ' ')
        .replace(/&amp;/ig, '&')
        // .replace(/&nbsp;/,' ')
        .replace(/&lt;/ig, '<')
        .replace(/&gt;/ig, '>')
        .replace(/&quot;/ig, '"')
        .replace(/&apos;/ig, "'")
        .replace(/&cent;/ig, '¢')
        .replace(/&pound;/ig, '£')
        .replace(/&yen;/ig, '¥')
        .replace(/&euro;/ig, '€')
        .replace(/&copy;/ig, '©')
        .replace(/&reg;/ig, '®')
        .trim();

    return output;
}


export function filterData(array, filters) {

    if (!isNullOrUndefined(array) && !isNullOrUndefined(filters)) {

        let filtered_rows = array.filter((item) => {
            let result = false;
            for (var i = 0; i < filters.length; i++) {
                if (filters[i][3] === '==') {
                    if (item[filters[i][0]] === filters[i][1]) {
                        result = true;
                    }
                }
                else if (filters[i][3] === '!=') {
                    if (item[filters[i][0]] !== filters[i][1]) {
                        result = true;
                    }
                }
                else if (filters[i][3] === '<') {
                    if (item[filters[i][0]] < filters[i][1]) {
                        result = true;
                    }
                }
                else if (filters[i][3] === '>') {
                    if (item[filters[i][0]] > filters[i][1]) {
                        result = true;
                    }
                }
                else if (filters[i][3] === '<=') {
                    if (item[filters[i][0]] <= filters[i][1]) {
                        result = true;
                    }
                }
                else if (filters[i][3] === '>=') {
                    if (item[filters[i][0]] >= filters[i][1]) {
                        result = true;
                    }
                }
                else {
                    if (item[filters[i][0]] === filters[i][1]) {
                        result = true;
                    }
                }
            }
            return result;
        })

        return filtered_rows;
    }
    else {
        return []
    }
}

export function insertobjectToarray(arr, obj, index) {

    if (index < arr.length) {
        arr[index] = obj;
        return arr;
    }
    else {
        arr.push(obj);
        return arr
    }
}


export function numberFormat(number) {
    return number?.toLocaleString('en-IN')
}

export const statusFieldConversion = (status) => {
    return status ? 'Active' : 'Inactive';
}

export const yesNOFieldConversion = (status) => {
    return status ? 'Yes' : 'No';
}



export function isDate(_date) {
    const _regExp = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(_date);
}

export const ROWS_PER_PAGE = [25, 50, 100]
export const DEFAULT_ROWS_PER_PAGE = 25;
export const ALERT_TIMEOUT = 15000;
export const UPDATE_BUTTON = 'Update';
export const CREATE_BUTTON = 'Create';
export const ADDNEW_BUTTON = 'Add New';

export const reg_mail = /^[^A-Z]*$/;
export const reg_name_with_space = /^[a-zA-Z ]*$/;
export const reg_name_without_space = /^[a-zA-Z]*$/;
export const reg_mob = /^[6-9]+[0-9]*$/;

export const PAGINATION_ROW_LABEL = "Rows :";

export const max_file_size = 8388608;
export const max_file_size_string = "8MB";


export const router_links = {
    "deliveries":"/home/deliveries",
    "collection":"/home/collection",
    "customers":"/home/customers",
    "products":"/home/products",
    "users":"/home/users",
}
