import { CircularProgress } from '@mui/material'
import React from 'react'

function Loading() {
  return (
    <div className='d-flex flex-column flex-grow-1 justify-content-center align-items-center'>
      <div>
        <CircularProgress />
      </div> 
    </div>
  )
}

export default Loading

